<template>
  <el-row type="flex" justify="center" class="fac">
    <el-col class="flex jc fac">
      <section class="login-l"></section>
      <section class="login-r flex fjc fac fw facc">
        <span class="title fs-50">运动数据管理</span>
        <el-form :model="ruleForm" :rules="rules" class="mt-30" hide-required-asterisk :show-message="false"
                 label-position="top" ref="ruleForm">
          <el-form-item prop="phone" class="flex fac fw mb-10" size="mini">
            <span slot="label" class="label-color">
              手机号
            </span>
            <el-input v-model="ruleForm.phone"></el-input>
          </el-form-item>
          <el-form-item prop="password" class="flex fac fw mb-10" size="mini">
            <span slot="label" class="label-color">
              密码
            </span>
            <el-input show-password v-model="ruleForm.password"></el-input>
            <!-- <el-link :underline="false" class="mt-10 fs-18">忘记密码?</el-link> -->
          </el-form-item>
          <el-form-item class="flex fac fw mt-50">
            <el-button type="primary" class="wd-100" @click="submitForm('ruleForm')">
              登&nbsp;&nbsp;录
            </el-button>
          </el-form-item>
        </el-form>
      </section>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'login',
  data () {
    return {
      ruleForm: {
        phone: '',
        password: ''
      },
      rules: {
        phone: [{
          required: true,
          message: '请输入手机号',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }
          // {
          //   min: 3,
          //   max: 5,
          //   message: '长度在 3 到 5 个字符',
          //   trigger: 'blur'
          // }
        ]
      }
    }
  },
  methods: {
    submitForm (formName) {
      //debugger;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$api.login(this.ruleForm).then(res => {
            if (res.success) {
              this.$storage.setToken(res.data.token);
              this.$storage.setTokenExpiresTime(res.data.expiresTime);
              this.$storage.setUserInfo(res.data.user);
              setTimeout(() => {
                this.$router.push({ name: 'detail' })
              }, 500)
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.el-row {
  width: 100vw;
  height: 100vh;
  background: url("../assets/img/login/background.jpg") no-repeat 100% 100%;
  background-size: 100% 100%;

  .el-col {
    width: 60%;
    height: 60%;
    border-radius: 15px;
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.3);
    background: rgba(255, 255, 255, 0.2);

    .login-l {
      width: 55%;
    }

    .login-r {
      width: 45%;
      height: 100%;
      background: #FFFFFF;
      border-radius: 0 15px 15px 0;

      .title {
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
        line-height: 45px;
      }

      .el-form {
        width: 75%;

        .label-color {
          font-size: 0.18rem;
          font-family: Microsoft YaHei;
          font-weight: 300;
          color: #999999;
        }

        .el-form-item {
          /deep/ .el-form-item__label {
            width: 100%;
            padding: 0;
          }

          /deep/ .el-form-item__content {
            width: 100%;
          }
        }

        /deep/ input {
          height: 0.4rem;
          font-size: 0.17rem;
          border-left: none;
          border-right: none;
          border-top: none;
          border-radius: 0;
        }

        button {
          height: 0.58rem;
          background: linear-gradient(116deg, #FF7D43, #FEBA11);
          box-shadow: 0 7px 6px 0 rgba(255, 125, 67, 0.2);
          border-radius: 10px;
          border: none;
          font-size: 0.24rem;
        }

        button:active {
          opacity: 0.8;
          box-shadow: 0 0 0 0;
          transition: all 0.15s linear;
        }
      }
    }
  }
}
</style>
